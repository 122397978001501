import { sendOTP, verifyOTP, signInWithToken } from '../api'
import { auth } from '../../config/firebase'
import { signInWithCustomToken } from 'firebase/auth'

const actions = {
  async sendOTP ({ _ }, { mobile, country, isWhatsApp, captchaToken }) {
    try {
      const result = await sendOTP({ mobile, country, isWhatsApp, captchaToken })
      if (result) {
        return result
      } else {
        return false
      }
    } catch (err) {
      console.error('ERROR OTP', err)
      return false
    }
  },
  async verifyOTP ({ dispatch }, { mobile, otp }) {
    console.log('verifying OTP, mobile: ', otp, mobile)
    try {
      const result = await verifyOTP({ mobile, otp })
      console.log('got result', result)
      if (result.data.verified) {
        signInWithCustomToken(auth, result.data.token)
        return true
      } else {
        return false
      }
    } catch (err) {
      console.error('ERROR', err)
      return false
    }
  },
  async signInWithToken ({ _ }, token) {
    try {
      const result = await signInWithToken({ token })
      if (result.data.verified) {
        signInWithCustomToken(auth, result.data.token)
      }
      return true
    } catch (err) {
      console.error('ERROR', err)
      return false
    }
  }
}

export default {
  actions
}
