import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VuePapaParse from 'vue-papa-parse'
import 'roboto-fontface/css/roboto/sass/roboto-fontface.scss'
import { auth } from './config/firebase'
import { onAuthStateChanged } from 'firebase/auth'
import 'bootstrap-icons/font/bootstrap-icons.css'

import './style/btdm.scss'
import { VueReCaptcha } from 'vue-recaptcha-v3'

const ReCaptchaOptions = {
  siteKey: '6LesB54qAAAAAGArWnXqq2bYNogXM9LTfT_Tj22A',
  loaderOptions: {
    autoHideBadge: true
  }
}
createApp(App).use(store).use(router).use(VuePapaParse).use(VueReCaptcha, ReCaptchaOptions).mount('#app')

onAuthStateChanged(auth, (user) => {
  if (user) {
    store.dispatch('setUser', user)
  }
  if (!user && router.currentRoute.value.path !== '/login' && router.currentRoute.value.name !== 'Setup Billing' && router.currentRoute.value.name !== 'Manage Fluss' && router.currentRoute.value.name !== 'Manage Plan') {
    router.push('/login')
  } else if (!user && (router.currentRoute.value.name === 'Setup Billing' || router.currentRoute.value.name === 'Manage Fluss' || router.currentRoute.value.name === 'Manage Plan') && Object.keys(router.currentRoute.value.query).length < 1) {
    router.push('/')
  } else if (!user && (router.currentRoute.value.name === 'Setup Billing' || router.currentRoute.value.name === 'Manage Fluss' || router.currentRoute.value.name === 'Manage Plan') && Object.keys(router.currentRoute.value.query).length > 0) {
    console.log('')
  } else if (user && router.currentRoute.value.path === '/login') {
    router.push('/')
  }
})
