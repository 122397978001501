import { functions } from '../config/firebase'
import { httpsCallable } from 'firebase/functions'

const getUser = async (userId) => {
  const getUser = httpsCallable(functions, 'apiV1UserGetUser')
  return await getUser(userId)
}
const updateAccess = (access) => {
  const updateAccess = httpsCallable(functions, 'apiV1UpdateAccess')
  updateAccess(access).then((result) => {
    console.log(result)
  })
}
const isFlussUser = (mobile) => {
  const isUser = httpsCallable(functions, 'apiV1ContactsIsUser')
  return isUser({ mobile: mobile })
}
const revokeAccess = (invitation) => {
  const revokeAccess = httpsCallable(functions, 'apiV1RevokeAccess')
  return revokeAccess(invitation)
}
const createShareLink = (invitation) => {
  const giveLink = httpsCallable(functions, 'apiV1GiveLink')
  return giveLink(invitation)
}
const sendInvite = (invitation) => {
  const giveInvite = httpsCallable(functions, 'apiV1GiveInvite')
  return giveInvite(invitation)
}
const checkIfOwned = (flussId) => {
  const requestOwner = httpsCallable(functions, 'apiV1RequestOwner')
  return requestOwner(flussId)
}
const claimAccess = (flussId) => {
  const claimFluss = httpsCallable(functions, 'apiV1ClaimAccess')
  return claimFluss(flussId)
}
const sendOTP = ({ mobile, country, isWhatsApp, captchaToken }) => {
  if (mobile) {
    const authSend = httpsCallable(functions, 'apiV2AuthSendOtp')
    return authSend({ mobile, country, isWhatsApp, captchaToken })
  }
}
const verifyOTP = ({ mobile, otp }) => {
  if (mobile && otp) {
    const authVerify = httpsCallable(functions, 'apiV2AuthVerifyOtp')
    return authVerify({ mobile: mobile, otp: otp, isDashboard: true })
  }
}
const signInWithToken = ({ token }) => {
  const authVerify = httpsCallable(functions, 'apiV1AuthVerifyToken')
  return authVerify({ idToken: token })
}
const updateUser = (updates) => {
  const updateUserProfile = httpsCallable(functions, 'apiV1UpdateUser')
  return updateUserProfile({ updates })
}
const deleteAccount = (updates) => {
  const deleteUserAccount = httpsCallable(functions, 'apiV1DeleteUser')
  return deleteUserAccount()
}
const logAccess = ({ flussId, timestamp }) => {
  const logUserAccess = httpsCallable(functions, 'apiV1LogAccess')
  return logUserAccess({ flussId, timestamp })
}
const getLink = ({ link, name, mobile }) => {
  const getLinkFromURL = httpsCallable(functions, 'apiV1RequestInviteFromLink')
  return getLinkFromURL({ link, name, mobile })
}
const getLatestFirmwareVersion = () => {
  const getFirmware = httpsCallable(functions, 'apiV1RequestLatestFirmware')
  return getFirmware()
}
const updateFluss = ({ flussId, updates }) => {
  const updateTheFluss = httpsCallable(functions, 'apiV1UpdateFluss')
  return updateTheFluss({ flussId, updates })
}
const deleteDevice = (flussId) => {
  const deleteAFluss = httpsCallable(functions, 'apiV1DeleteDevice')
  return deleteAFluss({ flussId })
}
const transferFluss = ({ flussId, flussName, toUserId, planId, maxUsers }) => {
  const transferTheFluss = httpsCallable(functions, 'apiV1GiveOwnership')
  return transferTheFluss({ flussId, flussName, toUserId, planId, maxUsers })
}
const getAccessLogs = (details) => {
  const getLogs = httpsCallable(functions, 'apiV1RequestAccessLogs')
  return getLogs(details)
}

const getBillingPlanTypes = () => {
  const getPlanTypes = httpsCallable(functions, 'apiV1BillingGetPlanTypes')
  return getPlanTypes()
}
const getFlussPlan = (flussId) => {
  const getFlussPlan = httpsCallable(functions, 'apiV1BillingGetFluss')
  return getFlussPlan(flussId)
}
const getBillingPlan = (planId) => {
  const getBillingPlan = httpsCallable(functions, 'apiV1BillingGetPlan')
  return getBillingPlan(planId)
}
const setBillingPlan = (details) => {
  const setPlan = httpsCallable(functions, 'apiV1BillingUpdatePlan')
  return setPlan(details)
}
const getBillingAccount = async (accountId) => {
  const getAccount = httpsCallable(functions, 'apiV1BillingGetAccount')
  return await getAccount(accountId)
}
const setBillingAccount = async (details) => {
  const setAccount = httpsCallable(functions, 'apiV1BillingSetAccount')
  return await setAccount(details)
}
const getBillingInvoice = (invoiceId) => {
  const getBillingInvoice = httpsCallable(functions, 'apiV1BillingGetInvoice')
  return getBillingInvoice(invoiceId)
}
const setBillingInvoice = (details) => {
  const setBillingInvoice = httpsCallable(functions, 'apiV1BillingSetInvoice')
  return setBillingInvoice(details)
}
const deleteBillingInvoice = (details) => {
  const deleteBillingInvoice = httpsCallable(functions, 'apiV1BillingDeleteInvoice')
  return deleteBillingInvoice(details)
}
const getFlussSettings = (flussId) => {
  const getTheFlussSettings = httpsCallable(functions, 'apiV1FlussSettingsGet')
  return getTheFlussSettings(flussId)
}
const updateFlussSettings = ({ flussId, updates }) => {
  const updateTheFlussSettings = httpsCallable(functions, 'apiV1FlussSettingsUpdate')
  return updateTheFlussSettings({ flussId, updates })
}
const logout = async () => {
  const logout = httpsCallable(functions, 'apiV1AuthLogOut')
  return await logout()
}
const test = () => {
  const test = httpsCallable(functions, 'apiV1BillingRunPayment')
  return test()
}
const testCard = () => {
  const testCard = httpsCallable(functions, 'apiV1BillingRunCardCheck')
  return testCard()
}
const testInvoice = () => {
  const testInvoice = httpsCallable(functions, 'apiV1BillingRunInvoice')
  return testInvoice()
}
const customPlanRequest = (details) => {
  console.log('aPI', details)
  const setCustomPlanRequest = httpsCallable(functions, 'apiV1BillingSetCustomPlanRequest')
  return setCustomPlanRequest(details)
}

export {
  getUser,
  updateAccess,
  isFlussUser,
  revokeAccess,
  createShareLink,
  sendInvite,
  checkIfOwned,
  claimAccess,
  sendOTP,
  verifyOTP,
  updateUser,
  deleteAccount,
  logAccess,
  getLink,
  updateFluss,
  deleteDevice,
  transferFluss,
  getLatestFirmwareVersion,
  getAccessLogs,
  getBillingPlanTypes,
  getBillingPlan,
  setBillingPlan,
  getFlussPlan,
  getBillingAccount,
  setBillingAccount,
  signInWithToken,
  getBillingInvoice,
  setBillingInvoice,
  deleteBillingInvoice,
  getFlussSettings,
  updateFlussSettings,
  logout,
  test,
  testCard,
  testInvoice,
  customPlanRequest
}
